"use client"

import { useMemo, useState } from "react"
import clsx from "clsx"

import type { GID, UnlikelyCartLineInput } from "@unlikelystudio/commerce-connector"

import type { InferReturn, Nullish, PropsWithClassName } from "~/@types/generics"
import { formatCheckoutItems } from "~/lib/shopify/utils/format-checkout-items"
import { AddToCartCta } from "~/components/ui/AddToCartCta"
import { Image } from "~/components/ui/Image"
import { Link } from "~/components/ui/Link"
import type { ShopTheLookPanelProps } from "~/components/ui/Panels/ShopTheLook"
import type { TVariant } from "~/components/ui/ProductHeader/_data/types"
import ProductDetailsPrice from "~/components/ui/ProductHeader/components/ProductDetails/components/ProductDetailsPrice"
import VariantsRow from "~/components/ui/ProductHeader/components/ProductDetails/components/VariantsRow"
import { getProductTrackingDataWithCollections } from "~/providers/GTMTrackingProvider/utils/get-product-tracking-data-with-collections"
import { variantToTrackingData } from "~/providers/GTMTrackingProvider/utils/variant-to-tracking-data"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import type { serializeCollectionTree } from "~/managers/CollectionsTreeManager/_data/serializer"
import { useCollectionTree } from "~/managers/CollectionsTreeManager/hooks"
import { usePanel } from "~/managers/PanelManager"
import numberAsBoolean from "~/utils/number-as-boolean"

import { sprinkles } from "~/styles/sprinkles.css"
import { breakpoints } from "~/styles/variables/breakpoints"

import * as css from "./styles.css"

type ShopTheLookItemProps = PropsWithClassName<{
  item: NonNullable<ShopTheLookPanelProps["items"]>[number]
  // Only for VariantsRow currentVariant prop
  activeVariantProps?: TVariant
  onSelectedVariantsChange?: ({
    activeVariant,
    productId,
  }: {
    activeVariant: TVariant
    productId: GID
    checkoutItem: UnlikelyCartLineInput
  }) => void
  onClearMobileSelect?: () => void
}>

const IMAGE_SIZES = [{ breakpoint: breakpoints.lg, ratio: 180 / 1440 }, { ratio: 4 / 12 }]

export function ShopTheLookClientItem({
  className,
  item,
  activeVariantProps,
  onSelectedVariantsChange,
  onClearMobileSelect,
}: ShopTheLookItemProps) {
  const t = useTranslate()

  const { title, images, variants, defaultVariant, productType, link } = item

  const isPdpShopTheLook = Boolean(onSelectedVariantsChange)

  const { removeCurrent, reset } = usePanel()

  const [activeVariantState, setActiveVariantState] = useState<Nullish<TVariant>>(
    isPdpShopTheLook ? null : defaultVariant
  )
  const [collectionTree] = useCollectionTree()

  const checkoutItems = useMemo(() => {
    return getCheckoutItems({ activeVariant: activeVariantState, item, collectionTree })
  }, [activeVariantState])

  const isProductSelected = Boolean(activeVariantProps)

  const isOutOfStock = !activeVariantState?.availableForSale
  return (
    <div className={clsx(css.ShopTheLookItem, className)}>
      <div className={clsx(css.row)}>
        {images?.[0] && (
          <Link className={clsx(css.image)} {...link} onClick={removeCurrent}>
            <Image asPlaceholder sizesFromBreakpoints={IMAGE_SIZES} {...images?.[0]} ratio="180_253" />
            {isProductSelected && <div className={css.selectedTag}>{t("general_selected")}</div>}
          </Link>
        )}
        <div className={clsx(css.content)}>
          <span className={clsx(css.title)}>{title}</span>
          {defaultVariant && <ProductDetailsPrice pricesSize="small" className={clsx(css.price)} {...defaultVariant} />}
          <VariantsRow
            className={clsx(css.variants)}
            sprinklesCss={{
              marginTop: { mobile: 16, desktop: 24 },
              paddingLeft: 0,
              paddingRight: 0,
            }}
            withMobileSelect
            label={isPdpShopTheLook ? t("product_long_details_size") : undefined}
            withUnavailableDisabled={isPdpShopTheLook}
            variants={variants}
            currentVariant={activeVariantProps ?? activeVariantState}
            sizeGuideDisabled
            productType={productType}
            selectPrefixLabel={isPdpShopTheLook ? `${t("product_details_size")} : ` : undefined}
            labelAsSelectPlaceholder={isPdpShopTheLook}
            onChange={(activeVariantIndex) => {
              const activeVariant =
                typeof activeVariantIndex === "number" && numberAsBoolean(activeVariantIndex)
                  ? variants?.[activeVariantIndex]
                  : null

              if (!isPdpShopTheLook) {
                setActiveVariantState(activeVariant)
                return
              }

              const currentCheckoutItem = getCheckoutItems({ activeVariant, item, collectionTree })[0]

              if (activeVariant && item.id && currentCheckoutItem) {
                onSelectedVariantsChange?.({ activeVariant, productId: item.id, checkoutItem: currentCheckoutItem })
              }
            }}
            onClearMobileSelect={onClearMobileSelect}
            activeWithBackground
            isProductSelected={isProductSelected}
          />
          {!isPdpShopTheLook && (
            <div className={clsx(css.cta, sprinkles({ display: { mobile: "none", desktop: "block" } }))}>
              <AddToCartCta
                onSuccess={() => reset()}
                fill
                isOutOfStock={isOutOfStock}
                activeVariants={activeVariantState}
                checkoutItems={checkoutItems}
              />
            </div>
          )}
        </div>
      </div>
      {!isPdpShopTheLook && (
        <div className={clsx(css.cta, sprinkles({ display: { mobile: "block", desktop: "none" } }))}>
          <AddToCartCta
            onSuccess={() => reset()}
            fill
            isOutOfStock={isOutOfStock}
            activeVariants={activeVariantState}
            checkoutItems={checkoutItems}
          />
        </div>
      )}
    </div>
  )
}

function getCheckoutItems({
  activeVariant,
  item,
  collectionTree,
}: {
  activeVariant: Nullish<TVariant>
  item: NonNullable<ShopTheLookPanelProps["items"]>[number]
  collectionTree: InferReturn<typeof serializeCollectionTree>
}) {
  const { trackingData, collectionsHandles } = item

  const processedTrackingData = {
    ...getProductTrackingDataWithCollections(trackingData, collectionsHandles, collectionTree),
    ...(activeVariant ? variantToTrackingData(activeVariant) ?? {} : {}),
  }

  return formatCheckoutItems({ variant: activeVariant, trackingData: processedTrackingData })
}
