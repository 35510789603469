"use client"

import { useEffect, useRef, type ComponentProps } from "react"
import dynamic from "next/dynamic"
import clsx from "clsx"

import { useIsHover } from "@unlikelystudio/react-hooks"

import type { Nullish, PropsWithClassName } from "~/@types/generics"
import { STOCK_LOW } from "~/lib/shopify/constants"
import { useGetStockByMarket, useQueryStockParams } from "~/hooks/useGetStockByMarket"
import InlineCta from "~/components/ui/InlineCta"
import { type SizeGuidePanelProps } from "~/components/ui/Panels/SizeGuide"
import type { TProductHeader } from "~/components/ui/ProductHeader/_data/serializer"
import type { TVariant } from "~/components/ui/ProductHeader/_data/types"
import ProductVariantsSelector from "~/components/ui/ProductHeader/components/ProductDetails/components/ProductVariantsSelector"
import type { TSizeGuideSlice } from "~/components/slices/SizeGuideSlice/types"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { usePanel } from "~/managers/PanelManager"
import type { VariantsRowWithVariantSwitchProps } from "~/app/[locale]/products/[slug]/(components)/VariantsRowWithVariantSwitch"
import { useActiveVariant } from "~/app/[locale]/products/[slug]/(hooks)/useVariantSwitch"

import { sprinkles, type Sprinkles } from "~/styles/sprinkles.css"
import { showFrom } from "~/styles/utils/show-hide"
import { text } from "~/styles/variants"

import * as css from "./styles.css"

const SizeGuidePanel = dynamic(() => import("~/components/ui/Panels/SizeGuide"), { ssr: false })

export type VariantsRowProps = PropsWithClassName<
  {
    variants: TProductHeader["serializedProduct"]["variants"]
    onChange?: ComponentProps<typeof ProductVariantsSelector>["onChange"]
    sizeGuideDisabled?: boolean
    withMobileSelect?: boolean
    selectPrefixLabel?: string
    onClearMobileSelect?: () => void
    stockAlertEnabled?: boolean
    labelAsSelectPlaceholder?: boolean
    activeWithBackground?: boolean
    productType: string | null
    label?: string
    sizeGuide?: Nullish<TSizeGuideSlice>
    withUnavailableDisabled?: boolean
    sprinklesCss?: Sprinkles
    isProductSelected?: boolean
  } & (
    | {
        defaultVariant: Nullish<TVariant>
        currentVariant?: never
      }
    | {
        currentVariant: Nullish<TVariant>
        defaultVariant?: never
      }
  )
>

function VariantsRow({
  className,
  variants,
  defaultVariant,
  currentVariant,
  withMobileSelect,
  labelAsSelectPlaceholder = false,
  productType,
  stockAlertEnabled,
  sizeGuideDisabled,
  sizeGuide,
  isInAddToCartPopin = false,
  activeWithBackground = false,
  withUnavailableDisabled = false,
  label,
  onChange: changeFunction,
  sprinklesCss,
  onClearMobileSelect,
  isProductSelected,
  selectPrefixLabel,
}: VariantsRowProps & Pick<VariantsRowWithVariantSwitchProps, "isInAddToCartPopin">) {
  const { add } = usePanel()
  const t = useTranslate()

  const [selectedVariant] = useActiveVariant()
  const activeVariant = defaultVariant ? selectedVariant ?? defaultVariant : currentVariant

  const onChange = (key: number | undefined) => {
    if (key !== undefined) {
      changeFunction?.(key)
    }
  }

  const openStylePanel = ({ productType, content }: SizeGuidePanelProps) => {
    add(<SizeGuidePanel productType={productType} content={content} />)
  }
  const hideSizeguideCta = !productType || sizeGuideDisabled

  const [isHover, callbacks] = useIsHover()
  const hoverTimes = useRef<number>(0)

  useEffect(() => {
    hoverTimes.current += 1
  }, [isHover])

  return (
    <div
      className={clsx(
        css.VariantsRow,
        className,
        sprinkles({
          marginTop: { mobile: 22, desktop: 30 },
          paddingLeft: { mobile: 10, desktop: 0 },
          paddingRight: { mobile: 10, desktop: 0 },
          position: "relative",
          ...(sprinklesCss ?? {}),
        })
      )}
    >
      {isInAddToCartPopin ? (
        <p
          className={clsx(
            labelAsSelectPlaceholder && withMobileSelect && showFrom("desktop", "block"),
            sprinkles({ textAlign: "center", marginBottom: 20 }),
            text({
              design: "body-neue-14",
              color: "grey-tundora",
            })
          )}
        >
          {label ?? t("product_details_size")}
        </p>
      ) : (
        <p
          className={clsx(
            labelAsSelectPlaceholder && withMobileSelect && showFrom("desktop", "block"),
            sprinkles({ marginBottom: 12 }),
            text({
              design: "neue-13-12",
              color: "grey-tundora",
            })
          )}
        >
          {label ?? t("product_details_size")}
        </p>
      )}
      <div className={clsx(css.ctasContainer, isInAddToCartPopin && css.ctasContainerInAddToCartPopin)}>
        {variants && (
          <ProductVariantsSelector
            isInAddToCartPopin={isInAddToCartPopin}
            withMobileSelect={withMobileSelect}
            variants={variants}
            stockAlertEnabled={stockAlertEnabled}
            activeVariant={activeVariant}
            onChange={onChange}
            activeWithBackground={activeWithBackground}
            withUnavailableDisabled={withUnavailableDisabled}
            // God please forgive me for this line
            placeholderLabel={labelAsSelectPlaceholder ? label?.replace(":", "") : undefined}
            onClearMobileSelect={onClearMobileSelect}
            isProductSelected={isProductSelected}
            selectPrefixLabel={selectPrefixLabel}
          />
        )}
        <div
          className={clsx(
            css.sizeGuideRow,
            css.sizeGuideRowCta[hideSizeguideCta ? "noCta" : "default"],
            isInAddToCartPopin && css.sizeGuideInAddToCartPopin
          )}
        >
          {activeVariant && <StockLow selectedVariant={activeVariant} display={{ mobile: "block", desktop: "none" }} />}
          {!sizeGuideDisabled && (
            <InlineCta
              onClick={() => openStylePanel({ productType, content: sizeGuide })}
              {...callbacks}
              className={clsx(css.sizeguideTrigger, isInAddToCartPopin && css.sizeGuideTriggerExtraMargin)}
              withDefaultLine
            >
              {t("size_guide")}
            </InlineCta>
          )}
        </div>
      </div>
      {activeVariant && <StockLow selectedVariant={activeVariant} display={{ mobile: "none", desktop: "block" }} />}
    </div>
  )
}

type StockLowProps = {
  selectedVariant: TVariant | undefined
  display: Record<string, string>
}

function StockLow({ selectedVariant, display }: StockLowProps) {
  const t = useTranslate()
  const params = useQueryStockParams()
  const { data } = useGetStockByMarket(selectedVariant?.sku ? [selectedVariant?.sku] : [], params)
  const processedStock = data?.[0] ?? undefined

  return (
    ((processedStock !== undefined && processedStock <= STOCK_LOW) || selectedVariant?.isStockLow) && (
      <div className={clsx(css.lowStockAlert, sprinkles({ display }))}>{t("product_stock_low")}</div>
    )
  )
}

export default VariantsRow
