import { useQuery, type UseQueryOptions } from "@tanstack/react-query"

import type { GID } from "@unlikelystudio/commerce-connector"

import useLocale from "~/hooks/useLocale"
import { useQueryStatus } from "~/hooks/useQueryStatus"
import { serializeShopTheLook, type ShopTheLook } from "~/components/ui/Panels/ShopTheLook/_data/serializer"

type QueryOptions = {
  throwError?: boolean
  onError?(error: Error): void
  onSuccess?(data: ShopTheLook): void
} & Omit<UseQueryOptions<ShopTheLook, Error, ShopTheLook>, "queryFn" | "onError">

type Params = {
  gids: GID[]
}

export function useGetShopTheLookItems({ gids }: Params, queryOptions?: QueryOptions) {
  const locale = useLocale()
  const { queryKey = [gids], ...options } = queryOptions ?? {}

  const response = useQuery<ShopTheLook, Error, ShopTheLook>({
    queryKey: ["fetchShopTheLookProducts", ...queryKey],
    queryFn: async () => {
      return (await serializeShopTheLook({ gids, locale })) ?? []
    },
    ...options,
  })

  useQueryStatus(response, options)

  return response
}
