"use client"

import { type ChangeEvent } from "react"
import clsx from "clsx"

import type { InferReturn, Nullish, PropsWithClassName } from "~/@types/generics"
import { STOCK_LOW } from "~/lib/shopify/constants"
import { useGetStockByMarket, useQueryStockParams } from "~/hooks/useGetStockByMarket"
import InlineCta from "~/components/ui/InlineCta"
import type { TVariant } from "~/components/ui/ProductHeader/_data/types"
import type serializeVariantsSelector from "~/components/ui/ProductHeader/components/ProductDetails/components/ProductVariantsSelector/_data/serializer"
import { VariantSelector } from "~/components/ui/VariantSelector"
import Select from "~/components/abstracts/Select"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { useIsFixedPanelOpen } from "~/managers/AddToCartFixedPanelManager/hooks"
import type { VariantsRowWithVariantSwitchProps } from "~/app/[locale]/products/[slug]/(components)/VariantsRowWithVariantSwitch"

import { sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

type ProductVariantsSelectorProps = Omit<InferReturn<typeof serializeVariantsSelector>, "defaultVariant" | "options"> &
  PropsWithClassName<{
    placeholderLabel?: string
    withRouterChange?: boolean
    withMobileSelect?: boolean
    onClearMobileSelect?: () => void
    stockAlertEnabled?: boolean
    activeWithBackground?: boolean
    activeVariant: Nullish<TVariant>
    onChange?: (index: number | undefined) => void
    withUnavailableDisabled?: boolean
    isProductSelected?: boolean
    selectPrefixLabel?: string
  }> &
  Pick<VariantsRowWithVariantSwitchProps, "isInAddToCartPopin">

export type VariantSelectorStates = "unavailable" | "stock_alert" | "default"

function ProductVariantsSelector({
  className,
  variants,
  activeVariant,
  withMobileSelect = false,
  placeholderLabel,
  stockAlertEnabled = false,
  onChange,
  isInAddToCartPopin,
  activeWithBackground = false,
  withUnavailableDisabled = false,
  onClearMobileSelect,
  isProductSelected,
  selectPrefixLabel,
}: ProductVariantsSelectorProps) {
  const t = useTranslate()
  const isFixedPanelOpenElement = useIsFixedPanelOpen()[0]

  const handleOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const index = Number(e.target.value)
    onChange?.(index)
  }

  const selectCurrentValue = variants.findIndex((variant) => variant.id === activeVariant?.id)

  return (
    <div className={clsx(css.wrapper)}>
      <div
        className={clsx(
          css.ProductVariantsSelector,
          className,
          isInAddToCartPopin && sprinkles({ justifyContent: "center" }),
          css.buttonsState[withMobileSelect ? "hiddenMobile" : "default"]
        )}
      >
        {variants?.map((variant, index) => (
          <VariantButton
            key={index}
            variant={variant}
            index={index}
            activeVariant={activeVariant}
            stockAlertEnabled={stockAlertEnabled}
            isFixedPanelOpenElement={isFixedPanelOpenElement}
            onChange={onChange}
            activeWithBackground={activeWithBackground}
            withUnavailableDisabled={withUnavailableDisabled}
          />
        ))}
      </div>
      <div className={clsx(css.selectContainer, css.selectState[withMobileSelect ? "mobileSelect" : "noSelect"])}>
        <Select
          id="quantity"
          state={isProductSelected ? "active" : "default"}
          currentValue={selectCurrentValue >= 0 ? selectCurrentValue : undefined}
          placeholder={placeholderLabel}
          className={clsx(css.select, sprinkles({ fontSize: 12, letterSpacing: 0.01, lineHeight: 1 }))}
          addRequiredIndicatorOnLabel
          theme={isProductSelected ? "white" : "black"}
          prefixLabel={selectPrefixLabel}
          name="quantity"
          aria-label={t("general_variants")}
          onChange={handleOnChange}
          options={variants.map((variant, index) => ({
            label: variant.size ?? "",
            value: index,
            disabled: withUnavailableDisabled && !variant.availableForSale,
          }))}
        />
        {isProductSelected && (
          <InlineCta withLine withDefaultLine color="grey-delta" onClick={onClearMobileSelect}>
            {t("cta_clear")}
          </InlineCta>
        )}
      </div>
    </div>
  )
}

type VariantButtonProps = {
  variant: ProductVariantsSelectorProps["variants"][number]
  index: number
  activeVariant: Nullish<TVariant>
  stockAlertEnabled?: boolean
  isFixedPanelOpenElement?: boolean
  activeWithBackground?: boolean
  onChange: ProductVariantsSelectorProps["onChange"]
  withUnavailableDisabled?: boolean
}
const VariantButton = ({
  variant,
  index,
  activeVariant,
  stockAlertEnabled,
  isFixedPanelOpenElement,
  activeWithBackground,
  onChange,
  withUnavailableDisabled = false,
}: VariantButtonProps) => {
  const isActive = variant.id === activeVariant?.id

  const { isStockLow, availableForSale, size, sku } = variant

  const params = useQueryStockParams()
  const { data } = useGetStockByMarket(sku ? [sku] : null, params)

  const processedStock = data?.[0]

  const variantState = (): VariantSelectorStates => {
    if (processedStock !== undefined) {
      if (processedStock === 0) {
        if (stockAlertEnabled) return "stock_alert"
        return "unavailable"
      } else {
        return "default"
      }
    } else {
      if (!availableForSale) {
        if (stockAlertEnabled) return "stock_alert"
        return "unavailable"
      }
      return "default"
    }
  }

  const state = variantState()

  const stockState = () => {
    const shouldDisplayLowStock = state !== "unavailable"
    if (state === "stock_alert") return "default"
    if (!shouldDisplayLowStock) return "default"
    return (processedStock !== undefined && processedStock <= STOCK_LOW) || isStockLow ? "low_stock" : "default"
  }

  const handleOnClick = () => {
    onChange?.(index)
  }

  return (
    <VariantSelector
      className={clsx(
        css.variantSelector,
        (isFixedPanelOpenElement || withUnavailableDisabled) && !availableForSale && css.disabled
      )}
      key={`option_${index}`}
      active={isActive}
      value={size}
      state={variantState()}
      stockState={stockState()}
      onClick={handleOnClick}
      activeWithBackground={activeWithBackground}
    />
  )
}

export { type ProductVariantsSelectorProps }
export default ProductVariantsSelector
