"use client"

import { useState, type ComponentProps } from "react"
import clsx from "clsx"

import type { GID, UnlikelyCartLineInput } from "@unlikelystudio/commerce-connector"

import type { Nullish, PropsWithClassName } from "~/@types/generics"
import { AddToCartCta } from "~/components/ui/AddToCartCta"
import type { ShopTheLookPanelProps } from "~/components/ui/Panels/ShopTheLook"
import { ShopTheLookClientItem } from "~/components/ui/Panels/ShopTheLook/components/ShopTheLookItem"
import type { TVariant } from "~/components/ui/ProductHeader/_data/types"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { usePanel } from "~/managers/PanelManager"

import { hideFrom, showFrom } from "~/styles/utils/show-hide"

import * as css from "./styles.css"

export type ShopThelookContentProps = PropsWithClassName<{
  items: ShopTheLookPanelProps["items"]
  fromProductHeaderShopTheLook?: boolean
}>

function ShopThelookContent({ className, items, fromProductHeaderShopTheLook }: ShopThelookContentProps) {
  const t = useTranslate()
  const { reset } = usePanel()
  const [selectedVariants, setSelectedVariants] = useState<SelectedVariants>({})

  function handleVariantsChange({
    activeVariant,
    productId,
    checkoutItem,
  }: Parameters<NonNullable<ComponentProps<typeof ShopTheLookClientItem>["onSelectedVariantsChange"]>>[0]) {
    setSelectedVariants((prev) => {
      const newPrev = { ...prev }

      if (newPrev[productId]?.variant.id === activeVariant.id) {
        delete newPrev[productId]
        return newPrev
      }
      if (activeVariant.availableForSale) {
        newPrev[productId] = { variant: activeVariant, checkoutItem }
      }

      return newPrev
    })
  }

  function handleClearMobileSelect(productId: Nullish<GID>) {
    if (!productId) {
      return
    }

    setSelectedVariants((prev) => {
      const newPrev = { ...prev }
      delete newPrev[productId]
      return newPrev
    })
  }

  const checkoutItems = getCheckoutItemsFromSelectedVariants(selectedVariants)

  if (!items || items?.length === 0) {
    return null
  }

  return (
    <>
      <div className={clsx(css.PanelContent, className)}>
        {items.map((item, index) => (
          <ShopTheLookClientItem
            key={`shop_the_look_item_${index}`}
            className={clsx(css.item)}
            item={item}
            activeVariantProps={
              fromProductHeaderShopTheLook && item.id ? selectedVariants[item.id]?.variant : undefined
            }
            onSelectedVariantsChange={fromProductHeaderShopTheLook ? handleVariantsChange : undefined}
            onClearMobileSelect={() => handleClearMobileSelect(item.id)}
          />
        ))}
      </div>
      {fromProductHeaderShopTheLook && (
        <div className={css.AddToCartBlock}>
          <AddToCartCta
            size="big"
            withPrice
            onSuccess={() => reset()}
            fill
            disabled={checkoutItems.length === 0}
            activeVariants={getVariantsFromSelectedVariants(selectedVariants)}
            checkoutItems={checkoutItems}
          >
            {checkoutItems.length > 0 ? (
              t("cta_pdp_shop_the_look_add_to_cart", { smart_count: checkoutItems.length })
            ) : (
              <>
                <span className={hideFrom("desktop", "inline-flex")}>{t("cta_pdp_shop_the_look_empty_mobile")}</span>
                <span className={showFrom("desktop", "inline-flex")}>{t("cta_pdp_shop_the_look_empty")}</span>
              </>
            )}
          </AddToCartCta>
        </div>
      )}
    </>
  )
}

type SelectedVariants = Record<string, { variant: TVariant; checkoutItem: UnlikelyCartLineInput }>

function getVariantsFromSelectedVariants(selectedVariants: SelectedVariants) {
  return Object.values(selectedVariants).map(({ variant }) => variant)
}

function getCheckoutItemsFromSelectedVariants(selectedVariants: SelectedVariants) {
  return Object.values(selectedVariants).map(({ checkoutItem }) => checkoutItem)
}

export default ShopThelookContent
