"use client"

import clsx from "clsx"

import { useGetCustomer } from "@unlikelystudio/react-ecommerce-hooks"

import type { InferReturn, PropsWithClassName } from "~/@types/generics"
import serializePrice from "~/data/price/serializer"
import useLocale from "~/hooks/useLocale"
import { getDiscountFromPrices } from "~/components/ui/ProductHeader/_data/get-discount-from-prices"
import type { serializeProductHeaderSP } from "~/components/ui/ProductHeader/_data/serializer"
import { processVpPrice } from "~/utils/vp-prices/process-vp-prices"

import * as css from "./styles.css"

export type ProductDetailsPriceProps = Pick<
  InferReturn<typeof serializeProductHeaderSP>["variants"][number],
  "price" | "compareAtPrice" | "vpPrice"
> &
  PropsWithClassName<{
    pricesSize?: "small" | "large"
  }>

function ProductDetailsPrice({ className, price, compareAtPrice, vpPrice, pricesSize }: ProductDetailsPriceProps) {
  const { data: customer } = useGetCustomer()

  const { processedPrice, processedCompareAtPrice } = processVpPrice({ price, compareAtPrice, vpPrice, customer })
  const locale = useLocale()

  const serializedPrice =
    processedPrice?.amount && serializePrice(locale, processedPrice?.currencyCode, processedPrice?.amount)
  const serializedCompareAtPrice =
    processedCompareAtPrice?.currencyCode &&
    processedCompareAtPrice?.amount &&
    serializePrice(locale, processedCompareAtPrice?.currencyCode, processedCompareAtPrice?.amount)

  const compareAtPricePercentage = getDiscountFromPrices(
    processedCompareAtPrice?.amount ?? null,
    processedPrice?.amount ?? null
  )
  return (
    <div className={clsx(css.ProductDetailsPrice, className)}>
      {serializedCompareAtPrice && (
        <div className={clsx(css.compareAtPrice({ size: pricesSize }))}>{serializedCompareAtPrice}</div>
      )}
      {compareAtPricePercentage && (
        <div
          className={clsx(css.compareAtPricePercentage({ size: pricesSize }))}
        >{`- ${compareAtPricePercentage}%`}</div>
      )}
      <div className={clsx(css.price({ size: pricesSize }))}>{serializedPrice}</div>
    </div>
  )
}

export default ProductDetailsPrice
