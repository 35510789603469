import { useEffect } from "react"
import { useParams } from "next/navigation"
import { useQuery } from "@tanstack/react-query"
import { atom, useAtom } from "jotai"
import { parseAsString, useQueryState } from "next-usequerystate"

import { getSfProductByHandle } from "~/lib/shopify/queries/get-sf-product-by-handle"
import { getVariantMinPrice } from "~/lib/shopify/utils/get-variant-min-price"
import { getReferenceIdFromGID } from "~/lib/shopify/utils/id"
import useLocale from "~/hooks/useLocale"
import type { TVariant } from "~/components/ui/ProductHeader/_data/types"
import serializeVariantsSelector from "~/components/ui/ProductHeader/components/ProductDetails/components/ProductVariantsSelector/_data/serializer"

type UseVariantSwitchArgs = {
  variants: TVariant[]
  defaultVariant: TVariant | null
}

const ActiveVariant = atom<TVariant | null>(null)

export function useActiveVariant() {
  return useAtom(ActiveVariant)
}

export function useVariantSwitch(args: UseVariantSwitchArgs) {
  const {
    data: { variants, defaultVariant },
  } = useGetVariantSwitch(args)

  const [variantIdParam, setVariantIdParam] = useQueryState("variant", parseAsString)

  const [_, setActiveVariant] = useActiveVariant()

  const activeVariant =
    variants?.find((variant) => getReferenceIdFromGID(variant.id)?.toString() === variantIdParam?.trim()) ??
    defaultVariant

  useEffect(() => {
    setActiveVariant(activeVariant)
  }, [activeVariant])

  const priceVariant = activeVariant ?? defaultVariant ?? getVariantMinPrice(variants)

  const onChange = (index: number | undefined) => {
    setActiveVariant(index === undefined ? defaultVariant : variants?.[index] ?? defaultVariant)

    if (index === undefined) {
      setVariantIdParam(null)
      return
    }

    const reference = getReferenceIdFromGID(variants[index]?.id)?.toString()
    reference && setVariantIdParam(reference)
  }

  return {
    activeVariant,
    priceVariant,
    onChange,
  }
}

function useGetVariantSwitch({ variants, defaultVariant }: UseVariantSwitchArgs) {
  const { slug } = useParams()
  const locale = useLocale()

  return useQuery({
    queryKey: ["ClientVariantsRowWithVariantSwitch", slug, locale],
    queryFn: async () => {
      const { productByHandle } = await getSfProductByHandle({
        handle: slug as string,
        locale: locale,
        includeQuantityAvailable: true,
      })

      if (!productByHandle)
        return {
          variants,
          defaultVariant,
        }

      const variantsSelector = serializeVariantsSelector(productByHandle, locale)

      return {
        variants: variantsSelector.variants,
        defaultVariant: variantsSelector.defaultVariant,
      }
    },
    enabled: !!slug && typeof slug === "string",
    keepPreviousData: true,
    initialData: () => ({
      variants,
      defaultVariant,
    }),
    staleTime: 3600,
  })
}
