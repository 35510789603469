"use client"

import { useCallback } from "react"
import clsx from "clsx"

import type { UnlikelyCartLineInput } from "@unlikelystudio/commerce-connector"
import { useGetCustomer } from "@unlikelystudio/react-ecommerce-hooks"

import type { Nullish } from "~/@types/generics"
import serializePrice from "~/data/price/serializer"
import { useAddToCart } from "~/hooks/useAddToCart"
import { useHasReachMaxAvailableQuantity } from "~/hooks/useHasReachMaxAvailableQuantity"
import useLocale from "~/hooks/useLocale"
import type { TVariant } from "~/components/ui/ProductHeader/_data/types"
import SquareCta, { type SquareCtaProps } from "~/components/ui/SquareCta"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { useIsFixedPanelOpen } from "~/managers/AddToCartFixedPanelManager/hooks"
import { processVpPrice } from "~/utils/vp-prices/process-vp-prices"

import type { AddToCartCtaVariants } from "./styles.css"
import * as css from "./styles.css"

export type AddtoCartCtaProps = SquareCtaProps & {
  isOutOfStock?: boolean
  productOutOfStock?: boolean
  activeVariants?: Nullish<
    | Pick<TVariant, "id" | "price" | "vpPrice" | "sku">[]
    | Pick<TVariant, "id" | "price" | "vpPrice" | "sku">
    | TVariant[]
  >
  checkoutItems?: UnlikelyCartLineInput[]
  withPrice?: boolean
  onClick?(): void
  onSuccess?(): void
  children?: React.ReactNode
  customIsLoading?: boolean
}

export function AddToCartCta({
  className,
  isOutOfStock = false,
  productOutOfStock = false,
  activeVariants: propsActiveVariants,
  onSuccess,
  checkoutItems = [],
  overrideTextPresets = false,
  withPrice = false,
  onClick,
  children,
  disabled,
  customIsLoading = false,
  ...props
}: AddtoCartCtaProps) {
  const { data: customer } = useGetCustomer()
  const [isFixedPanelOpen] = useIsFixedPanelOpen()
  const activeVariants = (Array.isArray(propsActiveVariants) ? propsActiveVariants : [propsActiveVariants]).filter(
    Boolean
  )

  const hasReachMaxAvailableQuantity = useHasReachMaxAvailableQuantity(activeVariants)

  const locale = useLocale()
  const priceAmount = activeVariants?.reduce((acc, variant) => {
    const variantQuantity = checkoutItems?.find((v) => v && v.merchandiseId === variant?.id)?.quantity ?? 0
    const { processedPrice } = processVpPrice({
      price: variant?.price,
      vpPrice: variant?.vpPrice,
      customer,
    })
    const priceAmount = processedPrice?.amount ?? 0

    return acc + priceAmount * variantQuantity
  }, 0)

  const serializedPrice =
    activeVariants?.[0]?.price?.currencyCode &&
    priceAmount &&
    serializePrice(locale, activeVariants?.[0]?.price?.currencyCode, priceAmount)

  const hasNoStock = isOutOfStock || productOutOfStock || hasReachMaxAvailableQuantity

  const disabledCta = hasNoStock || !activeVariants || disabled

  const kind: AddToCartCtaVariants = disabledCta ? "disabled" : "addToCart"

  const { mutate: addCartLines, isLoading } = useAddToCart(propsActiveVariants, onSuccess)

  const handleOnClick = useCallback(() => {
    if (onClick && !isFixedPanelOpen) {
      onClick?.()
      return
    }

    if (checkoutItems && checkoutItems?.length > 0) {
      addCartLines(checkoutItems)
    }
  }, [activeVariants, checkoutItems])

  return (
    <SquareCta
      data-comp={"UI/AddToCartCta"}
      className={clsx(css.AddToCartCta[kind], className)}
      fill={true}
      theme={"backgroundBlack"}
      {...props}
      onClick={handleOnClick}
      isLoading={isLoading || customIsLoading}
      disabled={disabledCta || isLoading}
      state={hasNoStock || disabled ? "disabled" : props?.state}
      overrideTextPresets={overrideTextPresets}
    >
      {children ?? (
        <Text
          conditions={{
            hasNoStock,
            activeVariants,
            isFixedPanelOpen,
            serializedPrice: serializedPrice ?? null,
            withPrice,
          }}
        />
      )}
    </SquareCta>
  )
}

function Text({
  conditions,
}: {
  conditions: {
    hasNoStock: boolean
    activeVariants: Pick<TVariant, "id" | "price" | "vpPrice">[]
    isFixedPanelOpen: boolean
    serializedPrice: string | 0 | null
    withPrice?: boolean
  }
}) {
  const t = useTranslate()
  const { hasNoStock, activeVariants, isFixedPanelOpen, serializedPrice, withPrice } = conditions

  if (hasNoStock) {
    return t("out_of_stock")
  }

  if (activeVariants && activeVariants?.length > 0 && isFixedPanelOpen) {
    return t("cart_fixed_popin_label")
  }

  if (activeVariants && activeVariants?.length > 0) {
    return [t("add_to_cart"), withPrice ? serializedPrice : null].filter(Boolean).join(` — `)
  }

  return t("choose_variant")
}
