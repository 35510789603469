import type { ReactNode } from "react"
import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import CloseButton from "~/components/ui/CloseButton"
import { usePanel } from "~/managers/PanelManager"

import * as css from "./styles.css"

export type PanelHeaderProps = PropsWithClassName<{
  title?: ReactNode
  children?: ReactNode
  size?: NonNullable<css.PanelHeaderVariants>["titleSize"]
}>

function PanelHeader({ className, children, title, size }: PanelHeaderProps) {
  const { removeCurrent } = usePanel()

  return (
    <header className={clsx(css.PanelHeader({ withTitle: Boolean(title), titleSize: size }), className)}>
      {title && <p>{title}</p>}
      {children}
      <CloseButton className={clsx(css.close)} width={14} onClick={removeCurrent} withHover />
    </header>
  )
}

export default PanelHeader
